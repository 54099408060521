<template>
  <div>
    <div class="comment-card">
      <div class="d-flex align-items-center mb-3">
        <div class="profile-pic mr-2">
          <img
            v-if="comment.user.img_url"
            class="w-100"
            :src="comment.user.img_url"
            alt=""
          />
          <img
            v-else
            class="w-100"
            src="../assets/images/profile/noprofile.png"
            alt=""
          />
        </div>
        <p class="text-big font-weight-bold m-0">
          {{ `${comment.user.first_name} ${comment.user.last_name}` }}
        </p>
      </div>
      <p>
        {{ comment.comment }}
      </p>
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <button class="likebtn">
            <!-- <b-icon-heart-fill v-if="comment.is_like" variant="primary" /> -->
            <div class="likectn" @click="like()">
              <img
                v-if="comment.is_like"
                src="../assets/images/like.svg"
                class="likeimg"
              />
              <img v-else src="../assets/images/unlike.svg" class="likeimg" />
            </div>
            <!-- <b-icon-hand-thumbs-up v-else variant="primary" /> -->
          </button>
          <p class="mb-0 mr-5 like_and_reply color-primary">
            {{ comment.total_like }}
          </p>
        </div>
        <button class="d-flex like_and_reply" @click="showReplies()">
          <b-icon
            :icon="
              showReply == true ? 'chat-right-text-fill' : 'chat-right-text'
            "
            style="margin:3px 5px 3px 3px; color:#0430ad;"
          />
          <p class="mb-0 color-primary">
            ตอบกลับ ({{ comment.replies.length }})
          </p>
        </button>
      </div>
    </div>

    <!-- Comment Reply Input -->
    <div v-show="showReply">
      <div class="comment-card ml-5">
        <div class="d-flex">
          <div class="profile-pic mr-2 flex-shrink-0">
            <img
              v-if="$store.getters.getUser.img_url"
              class="w-100"
              :src="$store.getters.getUser.img_url"
              alt=""
            />
            <img
              v-else
              class="w-100"
              src="../assets/images/profile/noprofile.png"
              alt=""
            />
          </div>
          <textarea
            class="replyBox"
            :rows="checkScreen() ? '1' : '3'"
            ref="input"
            :placeholder="'พิมพ์ข้อความตอบกลับ..'"
            v-model="comments"
          />
          <button class="submit-reply" @click="submitComment()">
            <!-- <b-icon-arrow-right-square-fill style="margin-left:10px" variant="primary" scale="2.25"/> -->
            <img src="../assets/images/submitComment.svg" alt="1px" />
          </button>
        </div>
      </div>
    </div>

    <!-- Comment Reply -->
    <div
      v-show="showReply"
      v-for="(reply, index) in comment.replies"
      :key="index"
    >
      <div class="comment-card ml-5">
        <div class="d-flex align-items-center mb-3">
          <div class="profile-pic mr-2 flex-shrink-0">
            <img
              v-if="reply.user.img_url"
              class="w-100"
              :src="reply.user.img_url"
              alt=""
            />
            <img
              v-else
              class="w-100"
              src="../assets/images/profile/noprofile.png"
              alt=""
            />
          </div>
          <p class="text-big font-weight-bold m-0">
            {{ `${reply.user.first_name} ${reply.user.last_name}` }}
          </p>
        </div>
        <div class="d-flex">
          <p v-if="reply.tag_user_id != null" class="color-primary">
            {{
              `${reply.tag_user.first_name} ${reply.tag_user.last_name}`
            }}&nbsp;
          </p>
          <p>
            {{ reply.reply }}
          </p>
        </div>
        <div class="d-flex">
          <div class="d-flex">
            <div class="d-flex align-items-center">
              <button class="likebtn" @click="likeReply(index)">
                <!-- <b-icon-heart-fill v-if="comment.is_like" variant="primary" /> -->
                <div class="likectn">
                  <img
                    v-if="reply.is_like"
                    src="../assets/images/like.svg"
                    class="likeimg"
                  />
                  <img
                    v-else
                    src="../assets/images/unlike.svg"
                    class="likeimg"
                  />
                </div>
                <!-- <b-icon-hand-thumbs-up v-else variant="primary" /> -->
              </button>
              <p class="mb-0 mr-5 like_and_reply color-primary">
                {{ reply.total_like }}
              </p>
            </div>
            <button
              class="d-flex like_and_reply"
              @click="
                toggleReply(
                  `${reply.user.first_name} ${reply.user.last_name}`,
                  index
                )
              "
            >
              <b-icon
                class="color-primary"
                :icon="
                  replyIndex == index
                    ? 'chat-right-text-fill'
                    : 'chat-right-text'
                "
                style="margin:3px 5px 3px 3px; color:#0430ad;"
              />
              <p class="mb-0 color-primary">ตอบกลับ</p>
            </button>
          </div>
        </div>
      </div>
      <div v-show="replyIndex == index">
        <div class="comment-card ml-5">
          <div class="d-flex">
            <div class="profile-pic mr-2">
              <img
                v-if="$store.getters.getUser.img_url"
                class="w-100"
                :src="$store.getters.getUser.img_url"
                alt=""
              />
              <img
                v-else
                class="w-100"
                src="../assets/images/profile/noprofile.png"
                alt=""
              />
            </div>
            <textarea
              class="replyBox"
              :rows="checkScreen() ? '1' : '3'"
              v-bind:ref="`rep`"
              :placeholder="'พิมพ์ข้อวามตอบกลับ..'"
              v-model="replies"
            >
                    <a>Hello World</a>
                  </textarea
            >
            <button @click="submitReply()">
              <!-- <b-icon-arrow-right-square-fill style="margin-left:10px" variant="primary" scale="2.25"/> -->
              <img src="../assets/images/submitComment.svg" alt="1px" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "process";
import axios from "../api/faAPI";
import baseUrl from "../util/backend";

export default {
  name: "Comment",
  data() {
    return {
      showReply: false,
      comments: "",
      replyTo: "",
      replyIndex: null,
      replies: "",
    };
  },
  props: {
    comment: Object,
    userid: Number,
  },
  methods: {
    showReplies() {
      this.showReply = !this.showReply;
      if (this.showReply) this.$nextTick(() => this.$refs.input.focus());
    },
    toggleReply(to, idx) {
      this.replyTo = to;
      this.replyIndex = idx;
      this.replies = `@${this.replyTo} `;
      this.$nextTick(() => this.$refs.rep[idx].focus());
    },
    checkScreen() {
      if (window.innerWidth < 1024) return false;
      return true;
    },

    //To Implement
    async submitComment() {
      //To make Data flow
      const commentReq = {
        user_id: this.userid,
        comment_id: this.$props.comment.comment_id,
        reply: this.comments,
        tag_user_id: null,
      };
      try {
        const res = await axios.post(`${baseUrl}/createReply`, commentReq);
        this.$nextTick(() => {
          this.comments = "";
          this.$props.comment.replies.push(res.data.data);
          this.reloadPage();
        });
      } catch (err) {
        if (err.response) {
          const error = new Error(err.response.data.error);
          alert(err.response.data.error);
          throw error;
        } else {
          throw err;
        }
      }
    },
    async like() {
      //To make Data flow
      const like = this.$props.comment.is_like;
      const total = this.$props.comment.total_like;
      const likeReq = {
        user_id: this.userid,
        comment_id: this.$props.comment.comment_id,
        is_like: !like,
      };
      try {
        const res = await axios.post(`${baseUrl}/like`, likeReq);
        if (res.status == 200) {
          this.$props.comment.total_like = like ? total - 1 : total + 1;
          this.$props.comment.is_like = !like;
        }
      } catch (err) {
        if (err.response) {
          const error = new Error(err.response.data.error);
          alert(err.response.data.error);
          throw error;
        } else {
          throw err;
        }
      }
    },
    async submitReply() {
      //To make Data flow
      const replyReq = {
        user_id: this.userid,
        comment_id: this.$props.comment.comment_id,
        reply:
          this.replies.substring(0, 1) == "@"
            ? this.replies.substring(`@${this.replyTo}`.length)
            : this.replies,
        tag_user_id:
          this.replies.substring(0, 1) == "@"
            ? this.$props.comment.replies[this.replyIndex].user_id
            : null,
      };
      try {
        const res = await axios.post(`${baseUrl}/createReply`, replyReq);
        this.$nextTick(() => {
          this.$props.comment.replies.push(res.data.data);
          this.replies = "";
          this.replyTo = "";
          this.replyIndex = null;
          this.reloadPage();
        });
      } catch (err) {
        if (err.response) {
          const error = new Error(err.response.data.error);
          alert(err.response.data.error);
          throw error;
        } else {
          throw err;
        }
      }
    },
    async likeReply(replyIndex) {
      const like = this.$props.comment.replies[replyIndex].is_like;
      const total = this.$props.comment.replies[replyIndex].total_like;
      const likeReq = {
        user_id: this.userid,
        reply_id: this.$props.comment.replies[replyIndex].reply_id,
        is_like: !like,
      };
      try {
        const res = await axios.post(`${baseUrl}/like`, likeReq);
        if (res.status == 200) {
          this.$props.comment.replies[replyIndex].total_like = like
            ? total - 1
            : total + 1;
          this.$props.comment.replies[replyIndex].is_like = !like;
        }
      } catch (err) {
        if (err.response) {
          const error = new Error(err.response.data.error);
          alert(err.response.data.error);
          throw error;
        } else {
          throw err;
        }
      }
    },
    reloadPage() {
      this.$emit("reload");
    },
  },
  watch: {
    replies(val) {
      const strlen = `@${this.replyTo}`.length;
      console.log(val.substring(1, strlen));
      if (
        val.substring(1, strlen) != this.replyTo &&
        val.substring(0, 1) == "@"
      ) {
        const newreply = this.replies.substring(strlen - 1);
        nextTick(() => {
          this.replies =
            newreply.substring(0, 1) == " "
              ? this.replies.substring(strlen)
              : newreply;
        });
      }
    },
  },
};
</script>

<style scoped>
button {
  background-color: transparent;
}

.comment-card {
  background-color: #f1f1f1;
  padding: 20px;
  margin: 10px;
}
.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  line-height: 0;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: #fff;
  line-height: 0;
  object-fit: cover;
}

.like_and_reply {
  padding-top: 3px;
  color: rgb(53, 121, 246);
}
.reply {
  padding-top: 2.7px;
  max-width: 130px;
  overflow: inherit;
  white-space: nowrap;
}
.replyBox {
  outline: none;
  margin-left: 5px;
  max-width: 95%;
  width: 100%;
  border: transparent;
  background: transparent;
}
.likeimg {
  height: 15px;
  width: 15px;
}
.likebtn {
  align-content: center;
}
.likectn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.submit-reply img {
  width: 100%;
}
</style>
