<template>
  <div>
    <!-- SECTION - Video player -->
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="video-container">
            <div
              v-show="videoLoading"
              class="video-loader d-flex justify-content-center align-items-center"
            >
              <b-spinner></b-spinner>
            </div>
            <iframe
              class="video-frame"
              id="stream-player"
              src=""
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p class="Dtext mt-2">
            {{ courseInfo.sub_text }}
          </p>
          <!-- div contact -->
          <div class="Dcontent-contact">
            <p>
              คุณสามารถลงทะเบียนใช้งาน VERSCAN ฟรี ได้แล้ววันนี้ ที่
              <a
                class="linktoVERSCAN"
                href="https://www.verscan.com"
                target="_blank"
                >&nbsp;https://www.verscan.com</a
              ><br />หรือสนใจสอบถามข้อมูลเพิ่มเติมทาง
            </p>
            <div class="contactElement d-flex align-items-center">
              <a
                href="https://line.me/ti/p/~@verscan"
                target="_blank"
                class="d-flex align-items-start"
              >
                <p class="d-block pl-1">💬 &nbsp;@VERSCAN</p></a
              >
            </div>
            <div class="contactElement d-flex">
              <a href="tel:0994146776" class="d-flex align-items-start">
                <p class="d-block pl-1">📞 &nbsp;09 9414 6776</p>
              </a>
            </div>
            <div class="contactElement d-flex">
              <a
                href="mailto:service@verscan.com"
                target="_blank"
                class="d-flex align-items-start"
              >
                <p class="d-block pl-1">📩 &nbsp;service@verscan.com</p></a
              >
            </div>
          </div>
          <div
            class="Ddownload-sheet"
            @click="
              getFile(
                $route.name === 'Lesson-5'
                  ? 'https://api.verscan.com/download/verscan_manual_TH_V_2.pdf'
                  : 'https://api.verscan.com/download/verscan_manual_TH_V_2.pdf',
                $route.name === 'Lesson-5'
                  ? 'verscan-maintenance.pdf'
                  : 'verscan-manual.pdf'
              )
            "
          >
            <a class="d-flex align-items-center">
              <img class="mr-2" src="../assets/images/pdf.png" alt="" />
              <p class="color-primary">ดาวน์โหลดเอกสารประกอบการเรียน</p>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="learning-progress text-white mb-2 mt-0">
            <p class="text-big mt-3">
              {{ courseInfo.name }}
            </p>
            <!-- <div class="d-flex align-items-center mb-3">
              <b-progress
                class="w-100 mr-2"
                height="10px"
                :value="learningPercent"
                :max="100"
                :precision="2"
                variant="success"
              ></b-progress>
              <img
                class="d-block"
                src="../assets/images/checknull.png"
                alt=""
              />
            </div> -->
            <!-- <p class="m-0 text-small">เรียนไปแล้ว {{ learningPercent }}%</p> -->
          </div>
          <p class="Mtext mt-2">
            {{ courseInfo.sub_text }}
          </p>
          <!-- div contact -->
          <div class="Mcontent-contact">
            <p>
              คุณสามารถลงทะเบียนใช้งาน VERSCAN ฟรี ได้แล้ววันนี้ ที่
              <a
                class="linktoVERSCAN"
                href="https://www.verscan.com"
                target="_blank"
                >&nbsp;https://www.verscan.com</a
              ><br />หรือสนใจสอบถามข้อมูลเพิ่มเติมทาง
            </p>
            <div class="contactElement d-flex align-items-center">
              <a
                href="https://line.me/ti/p/~@verscan"
                target="_blank"
                class="d-flex align-items-start"
              >
                <p class="d-block pl-1">💬 &nbsp;@VERSCAN</p></a
              >
            </div>
            <div class="contactElement d-flex">
              <a href="tel:0994146776" class="d-flex align-items-start">
                <p class="d-block pl-1">📞 &nbsp;09 9414 6776</p>
              </a>
            </div>
            <div class="contactElement d-flex">
              <a
                href="mailto:service@verscan.com"
                target="_blank"
                class="d-flex align-items-start"
              >
                <p class="d-block pl-1">📩 &nbsp;service@verscan.com</p></a
              >
            </div>
          </div>
          <div
            class="Mdownload-sheet"
            @click="
              getFile(
                'https://api.verscan.com/download/verscan-manual.pdf',
                'verscan-manual.pdf'
              )
            "
          >
            <a class="d-flex align-items-center">
              <img class="mr-2" src="../assets/images/pdf.png" alt="" />
              <p class="color-primary">ดาวน์โหลดเอกสารประกอบการเรียน</p>
            </a>
          </div>
          <p class="color-primary text-big font-weight-bold mt-2 mb-1">
            เนื้อหาทั้งหมด
          </p>
          <div class="content-list">
            <div
              class="item"
              v-for="(video, i) in courseInfo.videos"
              :key="i"
              :class="[
                currentVideo.video_id == video.video_id
                  ? 'selected'
                  : 'nonSelected',
                visitedVideoList.includes(video.video_id) ? 'visited' : '',
              ]"
              @click="changeEP(video)"
            >
              <div class="d-flex h-100">
                <img
                  class="mr-2"
                  style="margin-top: 5px"
                  src="../assets/images/play.svg"
                  alt=""
                />
                <div class="d-flex flex-column justify-content-between">
                  <div class="">
                    <p
                      class="contentName mb-1 mt-1 text-big"
                      style="font-weight: 550"
                    >
                      {{ video.name }}
                    </p>
                  </div>
                  <div class="d-flex">
                    <p class="m-0" style="font-size: 14px">
                      ใช้เวลา &nbsp;{{ video.duration }} &nbsp;นาที
                    </p>
                    <div v-if="currentVideo.id == video.id" class="learning">
                      กำลังเรียนอยู่
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="item"
              :class="[
                currentCourseId === content.id ? 'selected' : 'nonSelected',
              ]"
              v-for="content in courseInfo"
              :key="content.id"
              @click="goToLesson(content.id)"
            >
              <div class="d-flex h-100">
                <img
                  class="mr-2"
                  style="margin-top: 5px"
                  src="../assets/images/play.svg"
                  alt=""
                />
                <div class="d-flex flex-column justify-content-between">
                  <div class="">
                    <p
                      class="contentName mb-1 mt-1 text-big"
                      style="font-weight: 550"
                    >
                      {{ content.name }}
                    </p>
                  </div>
                  <div class="d-flex">
                    <p class="m-0" style="font-size: 14px">
                      ใช้เวลา &nbsp;{{ content.couresTime }} &nbsp;นาที
                    </p>
                    <div v-if="currentCourseId === content.id" class="learning">
                      กำลังเรียนอยู่
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <hr style="border-top: 3px solid #8888ff; margin: 50px 30%" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CourseCard from "../components/CourseCard.vue";
import Comment from "../components/Comment.vue";
import axios from "../api/faAPI";
import normAxios from "axios";
import baseUrl from "../util/backend";
import moment from "moment";
import { secToMin } from "../util/convertTime";
export default {
  components: {
    CourseCard,
    Comment,
  },
  props: {
    //props from router
    courseId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      visitedVideoList: [],
      mainLoading: false,
      courseLoading: false,
      cancelToken: null,
      commentInterval: null,
      videoLoading: false,
      courseDetail: null,
      lecturerDetail: null,
      courseInfo: {},
      currentVideo: {},
      comment: "",
      commentList: [],
      commentData: null,
      suggestList: [],
      time: "",
      lastStop: 0,
      relatedCourse: [],
      player: null,
      vidLink: "",
      learningPercent: 0,
      reviewPage: 1,
      displayDialog: false,
      playerInterval: null,
      isReviewed: false,
      review: [0, 0],
      userComment: "",
      userRating: 5,
      play: 0,
      courseData: [],
    };
  },
  async created() {
    if (!this.$route.query.ep) {
      this.$router.replace({ query: { ep: 1 } });
    }
    this.visitedVideoList = await this.$store.getters[
      "visit/getVisitedVideoList"
    ];
    const courseData = await this.$store.dispatch(
      "getVideoByCourseId",
      this.currentCourseId
    );

    this.courseInfo = {
      id: courseData.course.course_id,
      name: courseData.course.title,
      sub_text: courseData.course.sub_title,
      videos: courseData.course.link,
      couresTime: courseData.course.couresTime,
    };
    this.videoPermission();

    this.epID = this.$route.query.ep
      ? this.$route.query.ep
      : courseInfo.course.link[0].id;
    this.getVidData();

  },
  beforeRouteUpdate(_, _2, next) {
    if (this.player) {
      this.player.removeEventListener("play", this.sendGoogleAnalytics);
      this.player.destroy();
    }
    next();
  },

  computed: {
    userId() {
      return this.$store.getters.getUser.user_id;
    },
    currentCourseId() {
      return this.$props.courseId;
    },
  },
  watch: {
    "$route.query.ep"() {
      this.getVidData();
    },
  },
  methods: {
    async videoPermission() {
      let role = null;
      role = localStorage.getItem("right") ? await this.$store.dispatch("decryptToken", localStorage.getItem("right")) : 0;
      this.courseInfo.videos = this.courseInfo.videos.filter((video) => {
        if (video.permission) return video.permission.includes(parseInt(role));
        else return video;
      });

      if (this.courseInfo.videos.length > 0 && this.courseInfo.videos[0].permission) {
        this.$router.replace({ query: { ep: this.courseInfo.videos[0].id } });
      }
    },
    changeEP(video) {
      this.$router.push({ query: { ep: video.id } });
      this.currentVideo = { ...video };
      this.getVidData();
    },
    getFile(url, title) {
      axios({ method: "get", url, responseType: "arraybuffer" })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    secToMin,

    goToLesson(courseId) {
      this.$router.push(`/lesson/${courseId}`).catch(() => {});
    },
    //google analytic
    async getVidData() {
      this.currentVideo = this.$route.query.ep
        ? this.courseInfo?.videos.find(
            (vid) => vid.id === +this.$route.query.ep
          )
        : this.courseInfo.videos[0];
      if (this.player) {
        this.player.removeEventListener("play", this.sendGoogleAnalytics);
      }
      const newUrl = `https://customer-ude18ktnhun7cgzy.cloudflarestream.com/${this.currentVideo.link}/iframe?poster=${this.currentVideo.thumbnail}`;
      this.player = Stream(document.getElementById("stream-player"));
      let iFrame = document.querySelector("#stream-player");
      let iFrameParent = iFrame.parentElement;
      iFrame.remove();
      iFrame.setAttribute("src", newUrl);
      iFrameParent.append(iFrame);
      this.player = Stream(document.getElementById("stream-player"));
      this.player.addEventListener("play", this.sendGoogleAnalytics);
    },
    sendGoogleAnalytics() {
      console.log("sendGoogleAnalytics");
      console.log("play" + " " + this.currentVideo.name);
      this.$store.dispatch(
        "visit/getVisitedVideoList",
        this.currentVideo.video_id
      );
      this.$gtag.event(`play ${this.currentVideo.name}`, {
        event_category: "video",
      });
    },
    //   progressText(isEnd) {
    //     if (isEnd === true) {
    //       return "- สำเร็จแล้ว";
    //     } else if (isEnd === false) {
    //       return "- กำลังเรียนอยู่";
    //     } else {
    //       return "";
    //     }
    //   },
    //   setReview(index, value) {
    //     this.review.splice(index, 1, value);
    //   },
    //   nextReviewPage() {
    //     this.reviewPage += 1;
    //   },
    //   prevReviewPage() {
    //     this.reviewPage -= 1;
    //   },
    //   closeReview() {
    //     this.displayDialog = false;
    //     this.reviewPage = 1;
    //   },
    //   async submitReview() {
    //     const res = await axios.post(`${baseUrl}/createReview`, {
    //       user_id: this.userId,
    //       course_id: this.$route.params.courseId,
    //       content: this.review[0],
    //       detail: this.review[1],
    //       rating: this.userRating,
    //       comment: this.userComment,
    //     });
    //     console.log(res);
    //     this.displayDialog = false;
    //     this.reviewPage = 1;
    //     this.isReviewed = true;
    //   },
    //   setVideoTime() {
    //     if (this.lastStop) {
    //       if (this.player.currentTime < 0.01) {
    //         console.log("setVideoTime");
    //         this.player.currentTime = this.lastStop;
    //         this.time = this.lastStop;
    //         if (!this.player.paused) {
    //           this.player.play();
    //         }
    //       }
    //     }
    //   },
    //   async recordPlayTime() {
    //     this.playerInterval = setInterval(() => {
    //       // console.log(this.player.currentTime);
    //       this.time = this.player.currentTime;
    //       this.updateVideoLog();
    //     }, 5000);
    //   },
    //   stopRecodeTime() {
    //     console.log("pause!");
    //     clearInterval(this.playerInterval);
    //   },
    //   async updateVideoLog() {
    //     let percentFinish =
    //       (this.player.currentTime / this.player.duration) * 100;
    //     const updateRes = await axios.put(`${baseUrl}/updateVideo_log`, {
    //       video_id: +this.$route.query.vid,
    //       user_id: this.userId,
    //       second: this.player.currentTime,
    //       is_end: this.currentVideo.isEnd
    //         ? this.currentVideo.isEnd
    //         : percentFinish > this.currentVideo.percentEnd,
    //     });
    //     this.loadProgress();
    //   },
    // async loadVideo() {
    //   this.videoLoading = true;
    //   const CancelToken = normAxios.CancelToken;
    //   this.cancelToken = CancelToken.source();
    //   try {
    //     const res = await axios.post(
    //       `${baseUrl}/getSignedTokens`,
    //       {
    //         video_uid: this.$route.query.video,
    //       },
    //       {
    //         cancelToken: this.cancelToken.token,
    //       }
    //     );
    //     // let newUrl = `https://iframe.videodelivery.net/${res.data.token}`;
    //     this.player = Stream(document.getElementById("stream-player"));
    //     // let iFrame = document.querySelector("#stream-player");
    //     // let iFrameParent = iFrame.parentElement;
    //     // iFrame.remove();
    //     // iFrame.setAttribute("src", newUrl);
    //     // iFrameParent.append(iFrame);
    //     //---- set event listener for checking user view timestamp ----//
    //     this.player.addEventListener("play", this.recordPlayTime);
    //     this.player.addEventListener("pause", this.stopRecodeTime);
    //     this.player.addEventListener("canplay", this.setVideoTime);
    //     this.player.addEventListener("ended", this.updateVideoLog);
    //     //---- set event listener for checking user view timestamp ----//
    //   } catch (err) {
    //     if (err.response) {
    //       alert(err.response.data.error || "error");
    //     } else {
    //       if (normAxios.isCancel(err)) {
    //         return;
    //       } else {
    //         alert(err);
    //       }
    //     }
    //   }
    //   this.videoLoading = false;
  },
  //   async addComment() {
  //     if (this.comment) {
  //       let commentReq = {
  //         user_id: this.userId,
  //         comment: this.comment,
  //         course_id: this.$route.params.courseId,
  //       };
  //       try {
  //         await axios.post(`${baseUrl}/createComment`, commentReq);
  //         this.comment = "";
  //         await this.getComment();
  //         let test = this.$refs.comment;
  //         test.scrollTop = test.scrollHeight;
  //       } catch (err) {
  //         if (err.response) {
  //           alert(err.response.data.error || "error");
  //         } else {
  //           alert(err);
  //         }
  //       }
  //     }
  //   },
  //   async loadComment() {
  //     this.commentList = this.commentData;
  //     console.log("loadComment");
  //   },
  //   async getComment() {
  //     try {
  //       const res = await axios.get(`${baseUrl}/getAllComment`, {
  //         params: {
  //           course_id: this.$route.params.courseId,
  //           user_id: this.userId,
  //         },
  //       });
  //       this.commentData = res.data.data;
  //       this.loadComment();
  //     } catch (err) {
  //       if (err.response) {
  //         alert(err.response.data.error || "error");
  //       } else {
  //         alert(err);
  //       }
  //     }
  //   },
  //   async loadSuggest() {
  //     const res = await axios.get(`${baseUrl}/getSuggestCurriculum`);
  //     this.suggestList = res.data.data;
  //   },
  //   async loadProgress() {
  //     const res = await axios.get(`${baseUrl}/getCourseStudy`, {
  //       params: {
  //         course_id: this.$route.params.courseId,
  //         user_id: this.userId,
  //       },
  //     });
  //     const courseDetail = res.data.courseDetail;
  //     this.learningPercent = Math.ceil(courseDetail.progress);
  //     const videoArr = [];
  //     for (const video of courseDetail.playlist_result) {
  //       videoArr.push({
  //         id: video.playlist.video.video_id,
  //         name: video.playlist.video.name,
  //         duration: video.playlist.video.second,
  //         uid: video.playlist.video.url,
  //         percentEnd: video.playlist.video.percent_end,
  //         isEnd: video.is_end,
  //       });
  //     }
  //     this.courseInfo = videoArr;
  //   },
  //   async loadCourseStudy() {
  //     this.courseLoading = true;
  //     if (this.cancelToken) {
  //       this.cancelToken.cancel();
  //     }
  //     try {
  //       const res = await axios.get(`${baseUrl}/getCourseStudy`, {
  //         params: {
  //           course_id: this.$route.params.courseId,
  //           user_id: this.userId,
  //         },
  //       });
  //       console.log(res.data.courseDetail);
  //       const courseDetail = res.data.courseDetail;
  //       if (!this.$route.query.video) {
  //         if (courseDetail.playlist_result.length > 0) {
  //           if (courseDetail.latest_video) {
  //             this.$router.replace({
  //               name: "Lesson",
  //               params: { courseId: this.$route.params.courseId },
  //               query: {
  //                 vid: courseDetail.latest_video.video_id,
  //                 video: courseDetail.latest_video.url,
  //               },
  //             });
  //           } else {
  //             this.$router.replace({
  //               name: "Lesson",
  //               params: { courseId: this.$route.params.courseId },
  //               query: {
  //                 vid: courseDetail.playlist_result[0].playlist.video.video_id,
  //                 video: courseDetail.playlist_result[0].playlist.video.url,
  //               },
  //             });
  //           }
  //         } else {
  //           this.courseLoading = false;
  //           alert("ไม่มีวิดิโอในคอร์สนี้");
  //           this.$router.replace({ name: "MyCourse" });
  //         }
  //       } else {
  //         this.courseDetail = courseDetail.course.course;
  //         this.lecturerDetail = courseDetail.course.lecturer;
  //         this.learningPercent = Math.ceil(courseDetail.progress);
  //         const videoArr = [];
  //         for (const video of courseDetail.playlist_result) {
  //           videoArr.push({
  //             id: video.playlist.video.video_id,
  //             name: video.playlist.video.name,
  //             duration: video.playlist.video.second,
  //             uid: video.playlist.video.url,
  //             percentEnd: video.playlist.video.percent_end,
  //             isEnd: video.is_end,
  //           });
  //         }
  //         this.courseInfo = videoArr;
  //         // let check = false;
  //         // for await (const video of this.courseInfo) {
  //         //   if (+this.$route.query.vid === video.id) {
  //         //     check = true;
  //         //   }
  //         // }
  //         // if (!check) {
  //         //   this.$router.replace({
  //         //     name: "Lesson",
  //         //     params: { courseId: this.$route.params.courseId },
  //         //     query: {
  //         //       vid: courseDetail.playlist_result[0].playlist.video.video_id,
  //         //       video: courseDetail.playlist_result[0].playlist.video.url,
  //         //     },
  //         //   });
  //         // }
  //         try {
  //           const logRes = await axios.get(`${baseUrl}/getVideo_log`, {
  //             params: {
  //               course_id: this.$route.params.courseId,
  //               user_id: this.$store.getters.getUser.user_id,
  //               video_id: +this.$route.query.vid,
  //             },
  //           });
  //           console.log(logRes.data.data);
  //           this.lastStop = logRes.data.data.second || 0;
  //         } catch (err) {
  //           if (err.response) {
  //             console.log(err.response.data.error);
  //             if (
  //               err.response.data.error ===
  //               "Doesn't exist video_log in database"
  //             ) {
  //               const res = await axios.post(`${baseUrl}/createVideo_log`, {
  //                 user_id: this.$store.getters.getUser.user_id,
  //                 video_id: +this.$route.query.vid,
  //                 course_id: this.$route.params.courseId,
  //               });
  //               console.log(res);
  //               this.lastStop = 0;
  //             }
  //           } else {
  //             throw err;
  //           }
  //         }
  //         this.loadVideo();
  //         this.getComment();
  //         this.commentInterval = setInterval(() => {
  //           this.getComment();
  //         }, 10000);
  //       }
  //     } catch (err) {
  //       if (err.response) {
  //         alert(err.response.data.error || "error");
  //       } else {
  //         alert(err);
  //       }
  //     }
  //     this.courseLoading = false;
  //   },
  //   async checkOwnedCourse() {
  //     let livedCourse = [];
  //     const res = await axios.get(`${baseUrl}/getUserCourse`, {
  //       params: { user_id: this.$store.getters.getUser.user_id },
  //     });
  //     const timeRes = await axios.get(`${baseUrl}/getTime`);
  //     console.log(timeRes.data.type.unix);
  //     const currTime = timeRes.data.type.unix;
  //     let boughtCourse = res.data.data;
  //     for (const course of boughtCourse) {
  //       const dateNow = moment(currTime);
  //       const expireDate = course.course_expire
  //         ? moment(course.course_expire).unix()
  //         : null;
  //       console.log(expireDate);
  //       if (expireDate) {
  //         if (moment.unix(dateNow) < moment.unix(expireDate)) {
  //           livedCourse.push(course.course.course_id);
  //         }
  //       } else {
  //         livedCourse.push(course.course.course_id);
  //       }
  //     }
  //     if (!livedCourse.includes(+this.$route.params.courseId)) {
  //       throw "คุณไม่มีคอร์สนี้";
  //     }
  //   },
  //   async checkIsReview() {
  //     const res = await axios.get(`${baseUrl}/getReview`, {
  //       params: { course_id: this.$route.params.courseId },
  //     });
  //     for (const review of res.data.review) {
  //       if (review.user_id === this.$store.getters.getUser.user_id) {
  //         this.isReviewed = true;
  //       }
  //     }
  //   },
  //   async loadLessonData() {
  //     this.mainLoading = true;
  //     try {
  //       await this.checkOwnedCourse();
  //       this.loadCourseStudy();
  //       this.loadSuggest();
  //       this.checkIsReview();
  //     } catch (err) {
  //       alert(err);
  //       this.$router.replace({ name: "Home" });
  //     }
  //     this.mainLoading = false;
  //   },
  // },
  // created() {
  //   this.loadLessonData();
  //   this.loadVideo();
  //   this.commentData = comments;
  //   this.getComment();
  // },
  // beforeRouteUpdate(_, _2, next) {
  //   clearInterval(this.commentInterval);
  //   if (this.player) {
  //     this.stopRecodeTime();
  //     this.player.removeEventListener("play", this.recordPlayTime);
  //     this.player.removeEventListener("pause", this.stopRecodeTime);
  //     this.player.removeEventListener("ended", this.updateVideoLog);
  //     this.player.destroy();
  //   }
  //   next();
  // },
  // beforeRouteLeave(_, _2, next) {
  //   if (this.mainLoading || this.courseLoading) {
  //     next(false);
  //   } else {
  //     next();
  //   }
  // },
  // beforeDestroy() {
  //   this.stopRecodeTime();
  //   this.cancelToken.cancel();
  //   clearInterval(this.commentInterval);
  // },
};
</script>
<style>
/* .progress-bar {
  background-color: red;
} */
</style>

<style scoped lang="scss">
.learning {
  background-color: #ffbb0b;
  border-radius: 10px;
  margin-left: 10px;
  padding: 0 10px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 100;
  text-align: center;
}

.selected {
  background-color: #fcef79;
  &.visited {
    background-color: #fcef79;
  }
}

.nonSelected {
  background-color: #ffffff;
  &.visited {
    background-color: #dedede;
  }
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

textarea {
  outline: none;
  padding: 10px;
}

/* bootstrap */
.container {
  margin-bottom: 100px;
}

/* bootstrap */

/* vue bootstrap progressbar */
.progress {
  background-color: #c4c4c4;
}

/* vue bootstrap progressbar */

.video-container {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

.video-loader {
  background-color: #c7c7c7;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.video-frame {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.Ddownload-sheet,
.Mdownload-sheet {
  cursor: pointer;
  border: 1px solid #8888ff;
  width: fit-content;
  padding: 10px;
}

.Ddownload-sheet p,
.Mdownload-sheet p {
  margin: 0;
}

.instructor img {
  display: block;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.learning-progress {
  padding: 10px 10px;
  background-color: #8888ff;
}

.content-list {
  /* border: 1px solid #d5d5d5; */
  height: 293px;
  overflow: auto;
}

/* width */
.content-list::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.content-list::-webkit-scrollbar-track {
  background: #cccccc;
}

/* Handle */
.content-list::-webkit-scrollbar-thumb {
  background: #c0c0fa;
  border-radius: 3.5px;
}

/* Handle on hover */
.content-list::-webkit-scrollbar-thumb:hover {
  background: #07c5f8;
}

.content-list .item {
  cursor: pointer;
  border: 1px solid #d5d5d5;
  padding: 10px;
  margin: 0 6px 3px 0;
  height: 95px;
}

.content-list img {
  width: 18px;
  height: 18px;
}

.rating {
  padding: 10px;
  background-color: #07c5f8;
  cursor: pointer;
}

.rating:disabled {
  background-color: #d5d5d5;
  cursor: default;
}

.rating img {
  padding-right: 15px;
}

.qanda img {
  padding-right: 10px;
}

button.secondary {
  color: #fff;
  background-color: #07c5f8;
  padding: 10px 30px;
}

/* Comment */
.comment {
  max-height: 500px;
  overflow-y: auto;
}

.comment::-webkit-scrollbar {
  width: 10px;
}

.comment::-webkit-scrollbar-track {
  background: #a1a1a1;
  border-radius: 10px;
}

.comment::-webkit-scrollbar-thumb {
  background: #07c5f8;
  border-radius: 5px;
}

.comment-card {
  background-color: #f1f1f1;
  padding: 20px;
  margin: 10px;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  line-height: 0;
}

.more-course {
  cursor: pointer;
  border: none;
  background-color: #07c5f8;
  padding: 10px 25px;
}

/* review dialog */
.review hr {
  border-top: 3px solid #0430ad;
  margin: 20px 25%;
}

.review textarea {
  border: 2px solid #0430ad;
  outline: none;
}

.review textarea::-webkit-input-placeholder {
  text-align: center;
  color: #07c5f8;
  font-size: 18px;
}

.review textarea:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  color: #07c5f8;
  font-size: 18px;
}

.review textarea::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
  color: #07c5f8;
  font-size: 18px;
}

.review textarea:-ms-input-placeholder {
  text-align: center;
  color: #07c5f8;
  font-size: 18px;
}

.review button {
  display: block;
  background-color: #c4c4c4;
  width: 100%;
  border: none;
  color: white;
  padding: 10px 15px;
  /* margin: 10px auto; */
  max-width: 400px;
}

.review button.selected {
  background-color: #0430ad;
}

.text-line {
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-bottom: 3px solid #0430ad;
  line-height: 0.1em;
  margin: 40px auto;
}

.text-line span {
  background: #fff;
  padding: 0 10px;
}

* >>> .form-control:focus {
  box-shadow: none !important;
}

.choice {
  display: block;
  margin: 0 10px;
  background-color: #c4c4cc;
  width: 100%;
  max-width: 400px;
}

.review >>> .b-rating-star {
  font-size: 2rem;
}

.review .next-page {
  display: block;
  background-color: #07c5f8;
  border: none;
  color: white;
  min-width: 100px;
  max-width: 150px;
  padding: 10px 15px;
  margin: 50px auto 10px auto;
}

.contentName {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.linktoVERSCAN {
  color: #017afe;
  cursor: pointer;
}
.linktoVERSCAN:hover {
  color: #0060c6;
}

@media only screen and (min-width: 100px) {
  .review button {
    padding: 20px 15px;
  }

  .Ddownload-sheet {
    display: none;
  }

  .Dtext {
    display: none;
  }

  .learning-progress {
    margin-top: 20px;
  }
  .Dcontent-contact {
    display: none;
  }
  .Montent-contact {
    display: block;
  }
}

@media only screen and (min-width: 992px) {
  .review button {
    padding: 20px 15px;
  }

  .Mdownload-sheet {
    display: none;
  }

  .Mtext {
    display: none;
  }

  .Ddownload-sheet {
    display: block;
  }

  .Dtext {
    display: block;
  }

  .Dcontent-contact {
    display: block;
  }
  .Mcontent-contact {
    display: none;
  }
}

/* review dialog */
</style>
