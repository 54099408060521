var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-8"},[_c('div',{staticClass:"video-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoLoading),expression:"videoLoading"}],staticClass:"video-loader d-flex justify-content-center align-items-center"},[_c('b-spinner')],1),_c('iframe',{staticClass:"video-frame",attrs:{"id":"stream-player","src":"","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),_c('p',{staticClass:"Dtext mt-2"},[_vm._v(" "+_vm._s(_vm.courseInfo.sub_text)+" ")]),_vm._m(0),_c('div',{staticClass:"Ddownload-sheet",on:{"click":function($event){return _vm.getFile(
              _vm.$route.name === 'Lesson-5'
                ? 'https://api.verscan.com/download/verscan_manual_TH_V_2.pdf'
                : 'https://api.verscan.com/download/verscan_manual_TH_V_2.pdf',
              _vm.$route.name === 'Lesson-5'
                ? 'verscan-maintenance.pdf'
                : 'verscan-manual.pdf'
            )}}},[_vm._m(1)])]),_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{staticClass:"learning-progress text-white mb-2 mt-0"},[_c('p',{staticClass:"text-big mt-3"},[_vm._v(" "+_vm._s(_vm.courseInfo.name)+" ")])]),_c('p',{staticClass:"Mtext mt-2"},[_vm._v(" "+_vm._s(_vm.courseInfo.sub_text)+" ")]),_vm._m(2),_c('div',{staticClass:"Mdownload-sheet",on:{"click":function($event){return _vm.getFile(
              'https://api.verscan.com/download/verscan-manual.pdf',
              'verscan-manual.pdf'
            )}}},[_vm._m(3)]),_c('p',{staticClass:"color-primary text-big font-weight-bold mt-2 mb-1"},[_vm._v(" เนื้อหาทั้งหมด ")]),_c('div',{staticClass:"content-list"},_vm._l((_vm.courseInfo.videos),function(video,i){return _c('div',{key:i,staticClass:"item",class:[
              _vm.currentVideo.video_id == video.video_id
                ? 'selected'
                : 'nonSelected',
              _vm.visitedVideoList.includes(video.video_id) ? 'visited' : '',
            ],on:{"click":function($event){return _vm.changeEP(video)}}},[_c('div',{staticClass:"d-flex h-100"},[_c('img',{staticClass:"mr-2",staticStyle:{"margin-top":"5px"},attrs:{"src":require("../assets/images/play.svg"),"alt":""}}),_c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('div',{},[_c('p',{staticClass:"contentName mb-1 mt-1 text-big",staticStyle:{"font-weight":"550"}},[_vm._v(" "+_vm._s(video.name)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"m-0",staticStyle:{"font-size":"14px"}},[_vm._v(" ใช้เวลา  "+_vm._s(video.duration)+"  นาที ")]),(_vm.currentVideo.id == video.id)?_c('div',{staticClass:"learning"},[_vm._v(" กำลังเรียนอยู่ ")]):_vm._e()])])])])}),0)])]),_c('hr',{staticStyle:{"border-top":"3px solid #8888ff","margin":"50px 30%"}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Dcontent-contact"},[_c('p',[_vm._v(" คุณสามารถลงทะเบียนใช้งาน VERSCAN ฟรี ได้แล้ววันนี้ ที่ "),_c('a',{staticClass:"linktoVERSCAN",attrs:{"href":"https://www.verscan.com","target":"_blank"}},[_vm._v(" https://www.verscan.com")]),_c('br'),_vm._v("หรือสนใจสอบถามข้อมูลเพิ่มเติมทาง ")]),_c('div',{staticClass:"contactElement d-flex align-items-center"},[_c('a',{staticClass:"d-flex align-items-start",attrs:{"href":"https://line.me/ti/p/~@verscan","target":"_blank"}},[_c('p',{staticClass:"d-block pl-1"},[_vm._v("💬  @VERSCAN")])])]),_c('div',{staticClass:"contactElement d-flex"},[_c('a',{staticClass:"d-flex align-items-start",attrs:{"href":"tel:0994146776"}},[_c('p',{staticClass:"d-block pl-1"},[_vm._v("📞  09 9414 6776")])])]),_c('div',{staticClass:"contactElement d-flex"},[_c('a',{staticClass:"d-flex align-items-start",attrs:{"href":"mailto:service@verscan.com","target":"_blank"}},[_c('p',{staticClass:"d-block pl-1"},[_vm._v("📩  service@verscan.com")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../assets/images/pdf.png"),"alt":""}}),_c('p',{staticClass:"color-primary"},[_vm._v("ดาวน์โหลดเอกสารประกอบการเรียน")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Mcontent-contact"},[_c('p',[_vm._v(" คุณสามารถลงทะเบียนใช้งาน VERSCAN ฟรี ได้แล้ววันนี้ ที่ "),_c('a',{staticClass:"linktoVERSCAN",attrs:{"href":"https://www.verscan.com","target":"_blank"}},[_vm._v(" https://www.verscan.com")]),_c('br'),_vm._v("หรือสนใจสอบถามข้อมูลเพิ่มเติมทาง ")]),_c('div',{staticClass:"contactElement d-flex align-items-center"},[_c('a',{staticClass:"d-flex align-items-start",attrs:{"href":"https://line.me/ti/p/~@verscan","target":"_blank"}},[_c('p',{staticClass:"d-block pl-1"},[_vm._v("💬  @VERSCAN")])])]),_c('div',{staticClass:"contactElement d-flex"},[_c('a',{staticClass:"d-flex align-items-start",attrs:{"href":"tel:0994146776"}},[_c('p',{staticClass:"d-block pl-1"},[_vm._v("📞  09 9414 6776")])])]),_c('div',{staticClass:"contactElement d-flex"},[_c('a',{staticClass:"d-flex align-items-start",attrs:{"href":"mailto:service@verscan.com","target":"_blank"}},[_c('p',{staticClass:"d-block pl-1"},[_vm._v("📩  service@verscan.com")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../assets/images/pdf.png"),"alt":""}}),_c('p',{staticClass:"color-primary"},[_vm._v("ดาวน์โหลดเอกสารประกอบการเรียน")])])
}]

export { render, staticRenderFns }